import { sluggify } from '#root/shared/utils/types/string-utils';
import { type CategoryTreeItem as GqlCategoryTreeItem } from '../../shared/types/graphql-types';

interface GqlCategoryTreeItemKeyValue {
  key: 'entityId' | 'name' | 'path';
  value: string | number;
}

const findChild = (
  parent: GqlCategoryTreeItem,
  { key, value }: GqlCategoryTreeItemKeyValue
): GqlCategoryTreeItem | null => {
  if (parent?.children?.length) {
    return (
      parent.children.find(
        (child: GqlCategoryTreeItem) =>
          child[key] === value || findChild(child, { key, value })
      ) || null
    );
  }
  return null;
};

const findCategory = (
  category: GqlCategoryTreeItem,
  { key, value }: GqlCategoryTreeItemKeyValue
): GqlCategoryTreeItem | null => {
  if (category[key] === value) {
    return category;
  }

  if (category?.children?.length) {
    // eslint-disable-next-line no-restricted-syntax
    for (const child of category.children) {
      const match = findCategory(child, { key, value });
      if (match) {
        return match;
      }
    }
  }

  return null;
};

const slugMatchesCategoryName = (slug: string, categoryName: string) =>
  sluggify(categoryName).includes(slug);

export { findChild, findCategory, slugMatchesCategoryName };
