import { defineStore } from 'pinia';
import { toggleBodyScroll } from '~/helpers/utils';
import { gql } from '@apollo/client/core';
import { GRAPHQL_OPERATION_NAME } from '#root/shared/config/graphql-query-config';
import { categoryFragment } from '~/graphql/fragments';
import { findChild, slugMatchesCategoryName } from '~/utils/category-utils';

export interface Subcategory {
  entityId: number;
  name: string;
  description: string;
  pageTitle: string;
  metaDesc: string;
  active: boolean; // Currently this is not in use
  path: string;
}

export const useNavMenuStore = defineStore('navMenu', () => {
  const countryConfig = useShopCountryConfig();
  const language = useLanguage();

  const activeId = ref(0);
  const categoryTree = ref<any[]>([]);
  const animalTypes = ref<any[]>([]);
  const featuredCategories = ref<any[]>([]);
  const isNavMenuOpen = ref(false);
  const siblingCategories = ref<Subcategory[]>([]);
  const loading = ref(false);
  const loaded = ref(false);
  const error = ref(false);

  async function fetchCategoryTree() {
    if (loaded.value || loading.value) {
      return;
    }
    loading.value = true;
    const { data: categoryTreeData, error: categoryTreeError } =
      await useAsyncQuery({
        query: gql`
    query ${GRAPHQL_OPERATION_NAME.CategoryTree} {
      categoryTree {
        ...CategoryFields
        children {
          ...CategoryFields
          children {
            ...CategoryFields
            children {
              ...CategoryFields
              children {
                ...CategoryFields
              }
            }
          }
        }
      }
    }
    ${categoryFragment}
  `,
      });

    if (!categoryTreeError.value) {
      error.value = false;
      loaded.value = true;

      categoryTree.value = categoryTreeData?.value?.data?.categoryTree;

      const menuCategories = categoryTree.value?.filter((c: any) => c.menu);

      const hiddenCategories = categoryTree.value?.filter((c: any) => !c.menu);

      const categories = countryConfig.categories[language];

      animalTypes.value = menuCategories?.find((c: any) =>
        slugMatchesCategoryName(categories.animalType, c.name)
      )?.children;

      featuredCategories.value = hiddenCategories?.find((c: any) =>
        slugMatchesCategoryName(categories.featuredProducts, c.name)
      )?.children;
    } else {
      error.value = true;
    }
    loading.value = false;
  }

  function getAnimalTypeForCategory(categoryId: number) {
    if (animalTypes.value?.length) {
      return (
        animalTypes.value.find(
          (animalType) =>
            animalType.entityId === +categoryId ||
            findChild(animalType, { key: 'entityId', value: categoryId })
        ) || null
      );
    }

    return null;
  }

  function getAnimalTypesForCategories(categoryIds: number[]) {
    return categoryIds.reduce((animalTypes_: any[], categoryId) => {
      const animalType = getAnimalTypeForCategory(categoryId);

      if (animalType && !animalTypes_.includes(animalType)) {
        animalTypes_.push(animalType);
      }

      return animalTypes_;
    }, []);
  }

  async function getAnimalType(categoryIds: number[]) {
    const animalTypesResult = categoryIds.length
      ? await getAnimalTypesForCategories(categoryIds)
      : [];
    return animalTypesResult.length ? animalTypesResult[0].name : '';
  }

  function setActiveId(activeId_: number) {
    activeId.value = activeId_;
  }

  function setIsNavMenuOpen(isNavMenuOpen_: boolean) {
    const nuxtApp = useNuxtApp();

    isNavMenuOpen.value = isNavMenuOpen_;

    // Disable body scroll if not desktop and nav menu is open
    // Enable body scroll if desktop or nav menu is closed
    toggleBodyScroll(
      nuxtApp.$viewport.isGreaterThan('sm') || !isNavMenuOpen_,
      false
    );
  }

  return {
    fetchCategoryTree,
    getAnimalTypesForCategories,
    getAnimalType,
    setActiveId,
    setIsNavMenuOpen,
    isNavMenuOpen,
    activeId,
    animalTypes,
    siblingCategories,
    categoryTree,
    featuredCategories,
    loading,
    loaded,
    error,
  };
});
